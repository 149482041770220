import Boardle from './Boardle';
import './App.css';

function App() {
  return (
    <div className="App">
      <Boardle />
    </div>
  );
}

export default App;
